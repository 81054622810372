<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
            v-content
                v-snackbar(
                    :timeout="3000"
                    :top="true"
                    :right="true"
                    :color="contactColor"
                    v-model="contactMsj")
                        | {{ contactText }}
                        v-btn(flat dark @click.native="contactMsj = false") {{ $t('cerrar') }}
                v-container.grid-list-xl(fluid)
                    h1 {{$t('Sincronizar contactos')}}
                    p 
                        span.d-block(v-if="$root.hasCurrentNetwork") {{ $t('Sincroniza tus contactos de') }}&nbsp;{{ $root.currentNetwork.name }}&nbsp;{{ ('con tu cuenta de Google') }}
                        span.d-block(v-else) {{ $t('Sincroniza tus contactos de la plataforma con tu cuenta de Google') }}
                        small {{ $t('Los contactos se sincronizan automáticamente') }}
                    div(v-if="loadingConnections")
                        v-progress-circular(indeterminate color="primary")
                    v-layout(row wrap v-else)
                        v-flex(xs12)
                            v-btn.primary(v-if="!connections.length" @click="connectGoogle" :loading="loadingGoogle" depressed rounded) {{ $t('Conectar a una cuenta de Google') }}
                            v-btn.primary(v-else @click="connectGoogle" :loading="loadingGoogle" depressed rounded) {{ $t('Conectar una nueva cuenta de Google') }}
                            v-btn.primary(v-if="connections.length" depressed rounded @click="syncContact" :loading="loadingSync") {{ $t('Sincronizar ahora') }}
                        v-flex(xs12 v-if="connections.length")
                            h3 {{ $t('Cuentas de Google conectadas') }}
                        v-flex(xs12 sm3 v-for="(cn, i) in connections" :key="i")
                            .sheet.grey.lighten-3
                                .sheet-title 
                                    h2 {{ $t('Cuenta de Google conectada') }} 
                                    | {{ cn.email }}
                                .sheet-content
                                        h4.mb-2 {{ cn.synced }} {{ $t('contactos sincronizados de') }} {{ cn.totalToSync }}
                                        v-btn.ml-0.secondary(depressed rounded small @click="$refs.delete.open(cn)") {{ $t('Desconectar') }}
        confirm(ref="delete" @action="deleteAccount" :title="$t('Desconectar cuenta de Google')" :message="$t('¿Estás seguro de desconectar tu cuenta de Google?')")
</template>

<script>

    import auth from 'mixins/auth'

    export default {
        mixins: [auth], 
        metaInfo() {
            return {
                title: this.$t('Sincronizar contactos')
            }
        },
        data(){
            return {

                // UI
                loadingGoogle : false,
                loadingConnections: false,
                loadingSync : false,

                // Mensajes de error
                contactMsj    : false,
                contactColor  : '',
                contactText   : '',

                // Datos
                connections: []
            }
        },
        mounted(){
            this.fetchConnections()
        },
        methods: {

            /**
             * Conectar a Google
             */
            connectGoogle(){

                this.loadingGoogle = true
                this.$api(this, (xhr) => {
                    xhr.post('/contact/connect/google').then((r) => {

                        let data = r.data
                        this.loadingGoogle = false

                        if (data.response) {
                            window.location.href = data.data
                        }else{
                            this.contactMsj   = true
                            this.contactColor = 'error'
                            this.contactText  = data.message
                        }

                    }).catch((e) => {
                        this.loadingGoogle = false
                    })
                })
            },

            /**
             * Obtener conexiones
             */
            fetchConnections(){

                this.loadingConnections = true
                this.$api(this, (xhr) => {
                    xhr.get('/contact/connections').then((r) => {

                        let data = r.data
                        this.loadingConnections = false
                        this.connections = data.response ? data.data : []

                    }).catch((e) => {
                        this.loadingConnections = false
                    })
                })
            },

            /**
             * Desconectar cuenta de Google
             */
            deleteAccount({uuid}){

                this.$refs.delete.$emit('playLoader')
                this.$api(this, (xhr) => {
                    xhr.delete('/contact/disconnect/google?uuid=' + uuid).then((r) => {
                        
                        this.$refs.delete.$emit('stopLoader')
                        this.contactMsj = true

                        let data = r.data

                        if(data.response){
                            this.contactColor = 'success'
                            this.contactText = data.message
                            this.$refs.delete.close()
                            this.fetchConnections()
                        }else{
                            this.contactColor = 'error'
                            this.contactText = data.message
                        }
                    }).catch(() => {})
                })
            },

            /**
             * Sincronizar contactos
             */
            syncContact(){

                this.loadingSync = true
                this.$api(this, (xhr) => {
                    xhr.post('/contact/sync/trigger').then((r) => {

                        let data = r.data
                        this.loadingSync = false

                        if (data.response) {
                            this.contactColor = 'success'
                        }else{
                            this.contactColor = 'error'
                        }

                        this.contactMsj = true
                        this.contactText = data.message   

                    }).catch((e) => {
                        this.loadingSync = false
                    })
                })
            },
        }
    }

</script>